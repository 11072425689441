// fetchのラッパ

import _omitBy from 'lodash.omitby'
import _isNil from 'lodash.isnil'

import store from '@/store'

import AppError from '@/error/app-error'
import ErrorCodes from '@/error/codes'

export default async ({
  method = 'get',
  url,
  headers = undefined,
  params = undefined,
  body = undefined,
  // 認証が必要か
  needsAuth = false,
  // ローディング中にしないか
  hidesSpinner = false,
  // 整形用関数
  // スネークケース -> キャメルケースなど、必要最小限の変換に留めること
  transform = undefined,
}) => {
  if (!hidesSpinner) store.dispatch('app/incrementLoadings')

  let _url = /^https?:\/\//.test(url) ? url : (process.env.VUE_APP_API_BASE_URL || '') + url

  // クエリパラメタ
  if (params) {
    const searchParams = new URLSearchParams(params)
    _url = `${_url}?${searchParams.toString()}`
  }

  // リクエストボディ
  let _body = undefined
  if (body) {
    const omitted = _omitBy(body, _isNil)
    _body = JSON.stringify(omitted)
  }

  // ヘッダ
  let _headers = {}
  if (_body) _headers['Content-Type'] = 'application/json'
  if (needsAuth) {
    const token = store.getters['app/token']
    if (token) _headers['Authorization'] = token
  }
  if (headers) _headers = { ..._headers, ...headers }

  try {
    const options = {
      method,
      credentials: 'same-origin',
      body: _body,
      headers: _headers,
    }
    const res = await fetch(_url, options)
    const payload = await res.json()
    return {
      ok: res.ok,
      status: res.status,
      payload: res.ok && payload && transform ? transform(payload) : payload,
    }
  } catch (error) {
    console.error('an error occured during api access...', error)
    throw new AppError(ErrorCodes.NETWORK_ERROR, {
      orgError: error,
    })
  } finally {
    if (!hidesSpinner) store.dispatch('app/decrementLoadings')
  }
}
