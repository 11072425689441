// 受講者用の共通部分

<template lang="pug">
el-container.student-common(direction='vertical')

  el-header.header(height='40px')
    p 名古屋市私立幼稚園協会

  .outer
    router-view
</template>

<script>
export default {
  name: 'StudentCommon',
}
</script>

<style lang="sass" scoped>
.student-common

  .header
    background: $color-1
    color: $white
    line-height: 40px

  .outer
    min-height: 100vh
    background: $white
    padding: 1.5rem
    +mobile
      padding: 1rem 0.5rem
</style>
