import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// ポリフィル
import 'es6-promise/auto'
import 'whatwg-fetch'
import 'url-search-params-polyfill'
import '@/helpers/array-includes-polyfill.js'

// Element UI
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/ja'
import './assets/elementui.sass'

// tailwind
import './assets/tailwind.css'

// ローディング
import 'vue-loading-overlay/dist/vue-loading.css'

// fontawesome
import './fontawesome'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import './tinymce'

Vue.config.productionTip = false

Vue.use(ElementUI, { locale })
Vue.component('fa-icon', FontAwesomeIcon)

Vue.filter('jpDate', (dateStr) => {
  const [year, month, day] = dateStr.split(/[- :]/)
  return `${year}年${month}月${day}日`
})
Vue.filter('jpDateTime', (dateStr) => {
  const [year, month, day, hour, minute] = dateStr.split(/[- :]/)
  return `${year}年${month}月${day}日 ${hour}時${minute}分`
})
Vue.filter('timestampToJpDate', (timestamp) => {
  const d = new Date(timestamp)
  return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
