import storage from 'store'
import get from 'lodash.get'

import userService from '@/services/user'

// ローカルストレージのキー名
// アクセストークン
const tokenKey = 'token'

export default {
  namespaced: true,

  state: {
    // JWT
    token: null,
    // ユーザ情報
    me: null,
    // エラー
    error: null,
    // ローディング中の作業の数
    loadings: 0,
  },

  getters: {
    token(state) {
      return state.token
    },
    // ロール情報がなければ、'guest' とする
    role(state) {
      return get(state, 'me.role', 'guest')
    },
    // 名前
    name(state) {
      return get(state, 'me.name', '')
    },
    // ローディング中か
    isLoading(state) {
      return state.loadings > 0
    },
    // 現在発生中のエラー
    error(state) {
      return state.error
    },
  },

  actions: {
    // ログイン
    async login({ dispatch }, { loginName, password }) {
      const response = await userService.login(loginName, password)
      if (!response.ok) return false
      dispatch('storeToken', { token: response.payload.token })
      await dispatch('getMe', { force: true })
      return true
    },
    // 自分の情報を取得
    async getMe({ state, commit }, { force = false } = {}) {
      if (state.user && !force) return
      const response = await userService.getMe()
      if (response.ok) commit('setMe', response.payload)
    },
    storeToken({ commit }, { token }) {
      storage.remove(tokenKey)
      storage.set(tokenKey, token)
      commit('setToken', token)
    },
    restoreToken({ state, commit }) {
      if (state.token) return
      const token = storage.get(tokenKey)
      if (token) commit('setToken', token)
    },
    logout({ commit }) {
      storage.remove(tokenKey)
      commit('setToken', null)
      commit('setMe', null)
    },
    incrementLoadings({ state, commit }) {
      commit('setLoadings', state.loadings + 1)
    },
    decrementLoadings({ state, commit }) {
      commit('setLoadings', state.loadings - 1)
    },
    clearLoadings({ commit }) {
      commit('setLoadings', 0)
    },
    // エラーのセット
    setError({ commit }, error) {
      commit('setError', error)
    },
    // エラーのクリア
    clearError({ commit }) {
      commit('setError', null)
    },
  },

  mutations: {
    setMe(state, me) {
      state.me = me
    },
    setToken(state, token) {
      state.token = token
    },
    setLoadings(state, loadings) {
      if (loadings >= 0) state.loadings = loadings
    },
    // エラーのセット
    setError(state, error) {
      state.error = error
    },
  },
}
