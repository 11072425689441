import Vue from 'vue'
import VueRouter from 'vue-router'
import get from 'lodash.get'

import store from '@/store'
import studentCommon from '@/views/student/common'

Vue.use(VueRouter)

const routes = [
  // public
  {
    path: '/',
    redirect: '/public/login',
  },
  // 前のVerとの互換性を保つため
  {
    path: '/login',
    redirect: '/public/login',
  },
  {
    path: '/public/login',
    name: 'PublicLogin',
    component: () => import('@/views/public/login'),
    beforeEnter: (to, from, next) => {
      // ロールに応じて飛ばす先を決める
      const role = store.getters['app/role']
      if (role === 'org' || role === 'staff') {
        next({ name: 'MemberTop' })
      } else if (role === 'admin') {
        next({ name: 'AdminTop' })
      } else {
        next()
      }
    },
  },
  {
    path: '/join',
    name: 'PublicJoin',
    component: () => import('@/views/public/join'),
  },

  // 応募用紙のURL
  {
    path: '/group-application-form',
    name: 'AdminGroupApplicationForm',
    component: () => import('@/views/admin/group-application-form'),
    meta: {
      roles: ['admin'],
    },
  },

  // for admin
  {
    path: '/admin',
    component: () => import('@/views/common'),
    meta: {
      roles: ['admin'],
    },
    children: [
      {
        path: '',
        name: 'AdminTop',
        component: () => import('@/views/admin/top'),
        meta: {
          title: '管理画面トップ',
        },
      },
      {
        path: 'messages',
        name: 'AdminMessages',
        component: () => import('@/views/admin/messages'),
        meta: {
          title: 'メッセージの管理',
        },
      },
      {
        path: 'documents',
        name: 'AdminDocuments',
        component: () => import('@/views/admin/documents'),
        meta: {
          title: '各種様式の管理',
        },
      },
      {
        path: 'informations',
        name: 'AdminInformations',
        component: () => import('@/views/admin/informations'),
        meta: {
          title: 'お知らせの管理',
        },
      },
      {
        path: 'surveys',
        name: 'AdminSurveys',
        component: () => import('@/views/admin/surveys'),
        meta: {
          title: 'アンケートの管理',
        },
      },
      {
        path: 'surveys/:surveyId/form',
        name: 'SurveyForm',
        component: () => import('@/views/admin/survey-form'),
        meta: {
          title: 'アンケートフォームの編集',
        },
      },
      {
        path: 'surveys/:surveyId/result',
        name: 'SurveyResult',
        component: () => import('@/views/admin/survey-result'),
        meta: {
          title: 'アンケート集計結果',
        },
      },
      {
        path: 'groups',
        name: 'AdminGroups',
        component: () => import('@/views/admin/groups'),
        meta: {
          title: '受講者グループの管理',
        },
      },
      {
        path: 'organizations',
        name: 'AdminOrganizations',
        component: () => import('@/views/admin/organizations'),
        meta: {
          title: '会員園の管理',
        },
      },
      {
        path: 'app-users',
        name: 'AdminAppUsers',
        component: () => import('@/views/admin/app-users'),
        meta: {
          title: 'アプリユーザーの管理',
        },
      },
      {
        path: 'app-notifications',
        name: 'AdminAppNotifications',
        component: () => import('@/views/admin/app-notifications'),
        meta: {
          title: 'アプリ通知履歴',
        },
      },
    ],
  },

  // for member
  {
    path: '/member',
    component: () => import('@/views/common'),
    meta: {
      roles: ['org', 'staff'],
    },
    children: [
      {
        path: '',
        name: 'MemberTop',
        component: () => import('@/views/member/top'),
        meta: {
          title: 'マイページトップ',
        },
      },
      {
        path: 'informations',
        name: 'MemberInformations',
        component: () => import('@/views/member/informations'),
        meta: {
          title: 'お知らせ一覧',
        },
      },
      {
        path: 'messages',
        name: 'MemberMessages',
        component: () => import('@/views/member/messages'),
        meta: {
          title: 'メッセージ受信箱',
        },
      },
      {
        path: 'documents',
        name: 'MemberDocuments',
        component: () => import('@/views/member/documents'),
        meta: {
          title: '書類のダウンロード',
        },
      },
      {
        path: 'surveys',
        name: 'MemberSurveys',
        component: () => import('@/views/member/surveys'),
        meta: {
          title: 'アンケート',
        },
      },
      {
        path: 'surveys/:surveyId',
        name: 'MemberSurveyForm',
        component: () => import('@/views/member/survey-form'),
        meta: {
          title: 'アンケート回答',
        },
      },
      // 自園の情報
      {
        path: 'profile',
        name: 'MemberProfile',
        component: () => import('@/views/member/profile'),
        meta: {
          title: '自園情報',
        },
      },
    ],
  },

  // for students
  {
    path: '/s/:key',
    component: studentCommon,
    children: [
      {
        path: '',
        name: 'StudentTop',
        component: () => import('@/views/student/top'),
      },
      {
        path: 'surveys/:surveyId',
        name: 'StudentSurvey',
        component: () => import('@/views/student/survey'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  // ローカルストレージにあるトークンを、ストアにコピー
  store.dispatch('app/restoreToken')
  // ユーザ情報の取得
  await store.dispatch('app/getMe')

  // 当該ページにアクセスする権限があるかをチェック
  const role = store.getters['app/role']
  for (let item of to.matched) {
    const requiredRoles = get(item, 'meta.roles', [])
    if (requiredRoles.length === 0) continue
    else if (requiredRoles.indexOf(role) === -1) {
      const fallback = get(item, 'meta.fallback', 'PublicLogin')
      // fallbackが関数ならロールを渡した結果の戻り値を次の遷移先とする
      next({
        name: typeof fallback === 'function' ? fallback(role) : fallback,
      })
      return
    }
  }

  next()
})

export default router
