// ユーザ関連

import api from '@/helpers/api'

// 自分の情報の取得
async function getMe() {
  const url = '/me'
  return api({
    url,
    needsAuth: true,
  })
}

// ログインしてJWTトークンを取得
async function login(loginName, password) {
  const url = '/token'
  return api({
    method: 'post',
    url,
    body: {
      loginName,
      password,
    },
  })
}

// アクセスキーからJWT取得
async function getTokenByAccessKey(accessKey) {
  const path = '/token-by-access-key'
  return api({
    method: 'post',
    url: path,
    body: { accessKey },
  })
}

// 受講者メンバーの登録
async function registerStudent({
  number,
  code,
  organizationId,
  firstName,
  lastName,
  firstNameKana,
  lastNameKana,
  email,
}) {
  const path = '/user/students'
  return api({
    method: 'post',
    url: path,
    body: {
      loginName: number,
      groupCode: code,
      organizationId,
      firstName,
      lastName,
      firstNameKana,
      lastNameKana,
      email,
    },
  })
}

// アクティベーションメールの送信
async function sendActivationMail(userId) {
  const path = '/user/{id}/send'
  const url = path.replace('{id}', userId)
  return api({
    method: 'post',
    url,
  })
}

// グループへのメンバの追加 (管理者用)
async function createGroupMember(
  userGroupId,
  { number, organizationId, firstName, lastName, firstNameKana, lastNameKana, email }
) {
  const path = '/admin/user-groups/{id}/members'
  const url = path.replace('{id}', userGroupId)
  return api({
    method: 'post',
    url,
    body: {
      loginName: number,
      organizationId,
      firstName,
      lastName,
      firstNameKana,
      lastNameKana,
      email,
    },
    needsAuth: true,
  })
}

// グループの作成 (管理者用)
async function createGroup(name) {
  const path = '/admin/user-groups'
  return api({
    method: 'post',
    url: path,
    body: {
      name,
    },
    needsAuth: true,
  })
}

// グループの一覧を取得 (管理者用)
async function getGroupList(acceptableRole = null) {
  const path = '/admin/user-groups'
  let url = path
  return api({
    url,
    params: {
      acceptableRole,
    },
    needsAuth: true,
  })
}

// 特定のグループに所属するメンバーの取得 (管理者用)
async function getGroupMembers(userGroupId, all = false) {
  const path = '/admin/user-groups/{id}/members'
  let url = path.replace('{id}', userGroupId)
  return api({
    url,
    params: {
      all,
    },
    needsAuth: true,
  })
}

// ユーザの詳細情報の取得 (管理者用)
async function getUser(userId) {
  const path = '/admin/users/{id}'
  const url = path.replace('{id}', userId)
  return api({
    url,
    needsAuth: true,
  })
}

// グループの削除 (管理者用)
async function removeGroup(userGroupId) {
  const path = '/admin/user-groups/{id}'
  const url = path.replace('{id}', userGroupId)
  return api({
    url,
    method: 'delete',
    needsAuth: true,
  })
}

// ユーザの削除 (管理者用)
async function removeUser(userId) {
  const path = '/admin/users/{id}'
  const url = path.replace('{id}', userId)
  return api({
    url,
    method: 'delete',
    needsAuth: true,
  })
}

// グループの情報を更新 (管理者用)
async function updateGroup(userGroupId, name) {
  const path = '/admin/user-groups/{id}'
  const url = path.replace('{id}', userGroupId)
  return api({
    method: 'put',
    url,
    body: {
      name,
    },
    needsAuth: true,
  })
}

// ユーザ情報の更新 (管理者用)
async function updateUserInfo(
  userId,
  {
    userGroupId,
    organizationId,
    firstName,
    lastName,
    firstNameKana,
    lastNameKana,
    email,
    loginName,
    makeActivated = false,
  }
) {
  const path = '/admin/users/{id}'
  const url = path.replace('{id}', userId)
  return api({
    method: 'put',
    url,
    body: {
      userGroupId,
      organizationId,
      firstName,
      lastName,
      firstNameKana,
      lastNameKana,
      email,
      loginName,
      makeActivated,
    },
    needsAuth: true,
  })
}

// ユーザのログイン名の更新 (管理者用)
async function updateUserLoginName(userId, loginName) {
  const path = '/admin/users/{id}/login-name'
  const url = path.replace('{id}', userId)
  return api({
    method: 'put',
    url,
    body: {
      loginName,
    },
    needsAuth: true,
  })
}

// ユーザのパスワードの更新 (管理者用)
async function updateUserPassword(userId, password) {
  const path = '/admin/users/{id}/password'
  const url = path.replace('{id}', userId)
  return api({
    method: 'put',
    url,
    body: {
      password,
    },
    needsAuth: true,
  })
}

// アプリのユーザをすべて取得 (管理者用)
async function getApplicantsForAdmin() {
  const path = '/admin/applicants'
  return api({
    url: path,
    needsAuth: true,
  })
}

export default {
  getMe,
  login,
  getTokenByAccessKey,
  registerStudent,
  sendActivationMail,
  createGroupMember,
  createGroup,
  getGroupList,
  getGroupMembers,
  getUser,
  removeGroup,
  removeUser,
  updateGroup,
  updateUserInfo,
  updateUserLoginName,
  updateUserPassword,
  getApplicantsForAdmin,
}
