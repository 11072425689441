// エラーの詳細

import codes from './codes'

export const levels = {
  // 重大なエラー
  error: 'error',
  // 警告
  warn: 'warn',
  // 通知すべき事項
  info: 'info',
}

export default {
  // ユーザ関連
  [codes.AUTHENTICATE_FAILED]: {
    type: levels.warn,
    message: '認証に失敗しました。',
    num: 100,
  },

  // その他
  [codes.NETWORK_ERROR]: {
    type: levels.error,
    message: '通信エラーが発生しました。',
    num: 1000,
  },

  [codes.UNKNOWN_ERROR]: {
    type: levels.error,
    message: '予期せぬエラーが発生しました。',
    num: 1101,
  },
}
