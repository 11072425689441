import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faUserCircle,
  faHome,
  faEnvelope,
  faFileAlt,
  faGraduationCap,
  faClipboard,
  faBars,
  faCog,
  faPaperclip,
  faIdCard,
  faPlus,
  faTimes,
  faCheck,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faUserCircle,
  faHome,
  faEnvelope,
  faFileAlt,
  faGraduationCap,
  faClipboard,
  faBars,
  faCog,
  faPaperclip,
  faIdCard,
  faPlus,
  faTimes,
  faCheck
)
