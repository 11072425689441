// エラーコード

export default {
  // ユーザ関連 //////////////////////////

  // 認証失敗
  AUTHENTICATE_FAILED: 'AUTHENTICATE_FAILED',

  // その他 //////////////////////////////

  NETWORK_ERROR: 'NETWORK_ERROR',

  // 予期せぬエラー
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
}
