<template lang="pug">
#app
  router-view

  loading-overlay(
    :active='isLoading',
    :z-index='1000'
  )

  //- バージョン情報
  p.p-2.w-full.text-xs.text-center.text-gray-500.absolute.bottom-0
    | &copy; 名古屋市私立幼稚園協会
    | /
    | Ver.{{ version }}
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LoadingOverlay from 'vue-loading-overlay'

import AppError from '@/error/app-error'
import errorCodes from '@/error/codes'

export default {
  name: 'App',

  components: {
    LoadingOverlay,
  },

  computed: {
    ...mapGetters({
      isLoading: 'app/isLoading',
      error: 'app/error',
    }),
    version() {
      return process.env.VUE_APP_VERSION
    },
  },

  methods: {
    ...mapActions({
      clearLoadings: 'app/clearLoadings',
      clearError: 'app/clearError',
    }),
  },

  watch: {
    error(error) {
      if (!error) return

      // ローディング解除
      this.clearLoadings()

      const _error = error instanceof AppError ? error : new AppError(errorCodes.UNKNOWN_ERROR)

      const message = `${_error.message} (No.${_error.num})`
      const title = 'エラー'
      this.$alert(message, title, {
        confirmButtonText: 'OK',
      })
    },
  },
}
</script>

<style lang="sass">
html, body
  background-color: theme('colors.teal.100')
  color: theme('colors.gray.700')
  font-size: 14px
  +tablet
    font-size: 16px
body
  min-height: 100vh
  padding-bottom: 2rem
  position: relative

  a
    color: theme('colors.blue.600')
    text-decoration: none
    &:hover
      text-decoration: underline
</style>
