// アプリケーションエラークラス

// エラーの詳細の定義
import errorDetails from './details'

export default class AppError extends Error {
  // コンストラクタ
  // 別途定義されたエラーコードと、
  // オリジナルのエラーを伴う
  constructor(code, options = {}) {
    super()
    this._code = code
    if (options.orgError) this._orgError = options.orgError
    // ref:https://qiita.com/necojackarc/items/c77cf3b5368b9d33601b
    this.name = this.constructor.name
  }

  // エラーコード
  get code() {
    return this._code
  }

  // エラー番号
  get num() {
    return errorDetails[this._code].num
  }

  // エラーメッセージ
  get message() {
    return errorDetails[this._code].message
  }

  // エラーレベル
  get level() {
    return errorDetails[this._code].level
  }

  // オリジナルのエラー
  get orgError() {
    return this._orgError
  }

  // オリジナルのエラーがあるか
  get hasOrgError() {
    return !!this._orgError
  }

  // オリジナルエラーのメッセージ
  get orgErrorMessage() {
    return this._orgError?.message
  }
}
